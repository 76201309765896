<template>
<div style="margin-top: 80px">
    <BasePageBreadCrumb :pages="pages" title="novo técnico" :menu="menu"/>

    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card card-bg-trans">
                        <div class="card-body pt-4 px-0">
                            <form  class="px-2 needs-validation" novalidate @submit.prevent="save">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Nome </label>
                                                <input type="text" class="form-control" placeholder="" v-model="technical.first_name" v-validate="'required'" name="first_name" :class="{'is-invalid': errors.has('first_name')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Sobrenome </label>
                                                <input type="text" class="form-control" placeholder="" v-model="technical.last_name" v-validate="'required'" name="last_name" :class="{'is-invalid': errors.has('last_name')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>CPF </label>
                                                <input @blur="verifyDataUser('cpf')" type="text" class="form-control" placeholder="000.000.000-00" v-model="technical.cpf" v-validate="'required'" name="cpf" :class="{'is-invalid': errors.has('cpf')}" v-mask="'###.###.###-##'">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>E-mail </label>
                                                <input type="email" @blur="verifyDataUser('email')" class="form-control" placeholder="abc@example.com" v-model="technical.email" v-validate="'required|email'" name="email" :class="{'is-invalid': errors.has('email')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Whatsapp </label>
                                                <input type="text" class="form-control" placeholder="(99) 9 9999-9999" v-model="technical.cellphone" v-validate="'required'" name="cellphone" :class="{'is-invalid': errors.has('cellphone')}" v-mask="['(##) ####-####', '(##) # ####-####']">
                                            </div>
                                        </div>
                                        <div class="col-12"></div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Senha </label>
                                                <input type="password" class="form-control" id="passtext" placeholder="********" v-model="technical.password" ref="password" v-validate="passwordValidate" name="password" :class="{'is-invalid': errors.has('password')}">
                                            </div>
                                        </div>
                                        <div class="col-12"></div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Confirmar senha </label>
                                                <input type="password" class="form-control" id="passtext" placeholder="********" v-validate="confirmPasswordValidate" name="confirmed" :class="{'is-invalid': errors.has('confirmed')}">
                                            </div>
                                            <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Deve ter entre 8 e 20 caracteres.</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="reset" class="btn btn-dark">Cancelar</button>
                                        <button type="submit" class="btn ml-2 btn-info">Cadastrar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import TechnicalService from "@/services/resources/TechnicalService";
const service = TechnicalService.build();
import ConsultDataUserService from "@/services/resources/ConsultDataUser";
const consultService = ConsultDataUserService.build();

export default {
   data(){
       return{
           technical:{
               first_name: null,
               last_name: null,
               cpf: null,
               email: null,
               cellphone: null,
               password: null
           },
           id: null,
           pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'assistẽncia técnica',
                    to: 'Dashboard'
                },
                {
                    name: 'novo técnico',
                    to: 'TechnicalNew'
                }
            ],
            menu:[
                {
                    name: 'Novo chamado',
                    to: 'ReportNew'
                },
                {
                    name: 'Chamados',
                    to: 'Report'
                },
                {
                    name: 'Novo técnico',
                    to: 'TechnicalNew'
                },
                {
                    name: 'Técnicos',
                    to: 'Report'
                },
                {
                    name: 'Novo síndico',
                    to: 'LiquidatorNew'
                },
                {
                    name: 'Síndicos',
                    to: 'Liquidator'
                }
            ]
       }
   },
   computed:{
       passwordValidate(){
           return this.id ? 'min:8' : 'required|min:8';
       },
       confirmPasswordValidate(){
           return this.id ? 'confirmed:password' : 'required|confirmed:password';
       }
   },
   methods:{
     verifyDataUser(type){

            let data     = {};
            data['role_id'] = 1;//TECNICALL
            
            if(type === 'cpf') {
  
                data['cpf'] = this.technical.cpf;
      
                consultService
                    .search(data)
                    .catch(err => {
                        this.$bvToast.toast('Cpf já utilizado para este tipo de usuário', {
                            title: 'Cadastro de síndico',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })

            } else {

                    data['email'] = this.technical.email;
            
                     consultService
                    .search(data)
                    .catch(err => {
                        this.$bvToast.toast('E-mail já utilizado para este tipo de usuário', {
                            title: 'Cadastro de síndico',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })

            }

       },
       save(){
        this.$validator.validateAll().then((result) => {
            if (result) {
                let technical = Object.assign(this.technical)
                if(this.id){
                    technical.id = this.id;

                    service
                    .update(technical)
                    .then(resp => {
                    this.$bvToast.toast('Seu técnico foi atualizado com sucesso!', {
                        title: 'Técnico atualizado',
                        autoHideDelay: 5000,
                        type: 'success'
                        })
                    })
                    .catch(err => {
                        this.$bvToast.toast('Este e-mail já foi cadastrado no sistema.', {
                            title: 'Criação do técnico',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })
                }else{
                    service
                    .create(technical)
                    .then(resp => {
                    this.$bvToast.toast('Seu técnico foi criado com sucesso!', {
                        title: 'Técnico criado',
                        autoHideDelay: 5000,
                        type: 'success'
                        })
                    })
                    .catch(err => {
                        this.$bvToast.toast('Este e-mail já foi cadastrado no sistema.', {
                            title: 'Criação do técnico',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })
                }

          
            }
        });
       },
       fetchTechnical(){

        if(!this.id){
            return;
        }

        let data = {
            id: this.id
        }

        service
        .read(data)
        .then(resp => {
            this.technical = resp;
        })
        .catch(err => {
        console.log(err)
        })

       }
   },
   mounted(){
       this.id = this.$route.params.id;
       this.fetchTechnical();
   }
}
</script>